const projects = [
  {
    id: 1,
    name: 'Doc App',
    description:
      "An application to make an appointment with doctors. It's built with React as frontend and Rails as a backend. Doctors and Patients can register and manage their bookings. The patient can view the available timeslots of each doctor.",
    screenshot: 'https://raw.githubusercontent.com/rna/docapp/main/homepage.png',
    live: 'https://docapp.rameshnaidu.in/',
    repo: 'https://github.com/rna/docapp',
    languages: [
      { id: 1, name: 'Rails' },
      { id: 2, name: 'React' },
      { id: 3, name: 'Redux' },
      { id: 4, name: 'PostgreSQL' },
      { id: 5, name: 'Rspec' },
      { id: 6, name: 'ES6' },
      { id: 7, name: 'CSS' },
    ],
  },
  {
    id: 2,
    name: 'Stock Watch',
    description:
      'A web application built on React and Redux to monitor stocks, related stock information and its news. User can view the company profile and news of each available stock. User can also delete the stock in the list.',
    screenshot: 'https://raw.githubusercontent.com/rna/stock-watch/main/homepage.png',
    live: 'https://stockwatch.rameshnaidu.in/',
    repo: 'https://github.com/rna/stock-watch',
    languages: [
      { id: 1, name: 'React' },
      { id: 2, name: 'Redux' },
      { id: 3, name: 'Javascript' },
      { id: 4, name: 'CSS' },
    ],
  },

  {
    id: 3,
    name: 'Cinemadly',
    description:
      'This is a social networking application where users can follow each other, rate movies, view movies rated by other following users. Design is inspired from the Twitter.',
    screenshot: 'https://i.imgur.com/uhhByiS.png',
    live: 'https://cinemadly.herokuapp.com/',
    repo: 'https://github.com/rna/cinemadly',
    languages: [
      { id: 1, name: 'Rails' },
      { id: 2, name: 'Ruby' },
      { id: 3, name: 'Rspec' },
      { id: 4, name: 'PostgreSQL' },
      { id: 5, name: 'Cloudinary' },
      { id: 6, name: 'Bootstrap' },
    ],
  },
  {
    id: 4,
    name: 'Platform Game',
    description:
      'It is a platform game built with Javascript and Phaser 3 game engine. The concept of a game is that a player will help the monkey to eat bananas in the forest by escaping from the Wild Cats.',
    screenshot: 'https://raw.githubusercontent.com/rna/por-banana-game/master/game-scene.png',
    live: 'hhttps://por-banana.netlify.app/',
    repo: 'https://github.com/rna/por-banana-game',
    languages: [
      { id: 1, name: 'Phaser' },
      { id: 2, name: 'Javacript' },
      { id: 3, name: 'HTML' },
      { id: 4, name: 'CSS' },
    ],
  },
];

export default projects;
