import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Card, TitleWrap } from 'components/common';
import browserIcon from 'assets/icons/browser.svg';
import codeIcon from 'assets/icons/code.svg';
import { Wrapper, Grid, Item, Content, Stats, Languages } from './styles';
import projects from './projects';

export const Projects = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper as={Container} id="projects">
      <h2>Projects</h2>
      <Grid>
        {projects.map(node => (
          <Item key={node.id} theme={theme}>
            <Card theme={theme}>
              <Content>
                <h4>{node.name}</h4>
                <img src={node.screenshot} alt={node.name} />
                <p>{node.description}</p>
              </Content>
              <TitleWrap>
                <Stats theme={theme}>
                  <div>
                    {/* <Star color={theme === "light" ? "#000" : "#fff"} /> */}
                    <a href={node.live} target="_blank" rel="noopener noreferrer">
                      <img src={browserIcon} alt="live" />
                    </a>
                  </div>
                  <div>
                    {/* <Fork color={theme === "light" ? "#000" : "#fff"} /> */}
                    <a href={node.repo} target="_blank" rel="noopener noreferrer">
                      <img src={codeIcon} alt="repo" />
                    </a>
                  </div>
                </Stats>
                <Stats theme={theme}>
                  <Languages theme={theme}>
                    {node.languages.map(({ id, name }) => (
                      <span key={id}>{name}</span>
                    ))}
                  </Languages>
                </Stats>
              </TitleWrap>
            </Card>
          </Item>
        ))}
      </Grid>
    </Wrapper>
  );
};
